.navbar {
  position: sticky;
  top: 0px;
  height: 70px;
  background-color: #000c4d;
  opacity: 0.75;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px;
  margin-bottom: -70px;
  z-index: 5;
}

.navbar__logo {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  user-select: none;
}

/*
  This is the container for all the navbar links ( <ul> )
  */
.navbar__list {
  display: flex;
  list-style: none;
}

/*
  This is a single item ( <li> ) in the list
  */
.navbar__item {
  white-space: nowrap;
}

/*
  The anchor tag ( <a> ) for the navbar links
  el de color modifica el color de los botones de la barra superior derecha
  */
.navbar__link {
  font-size: 0.85rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 10px;
  margin: 0px 5px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: transparent;
  transition: 250ms;
}

.navbar__link:hover {
  border-color: white;
  transition: 250ms;
}

.navbar__menu {
  color: white;
  display: none;
  cursor: pointer;
}

/*
 Aca se edita los valores de la barra emergente menor a 800px
  */

@media screen and (max-width: 800px) {
  .navbar__list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    top: 70px;
    left: -120%;
    width: 75%;
    height: 100px;
    position: absolute;
    transition: all 0.5s ease;
  }

  .navbar__list--active {
    left: 0;
  }

  .navbar__menu {
    display: block;
  }

  .navbar__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    flex: 1;
  }

  .navbar__link {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0px;
    border-width: 0px;
    background-color: #000c4d;
    opacity: 0.75;
  }

  .navbar__link:hover {
    border-width: 0px;
    opacity: 0.9;
  }
}
