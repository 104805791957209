.bloquecontacto {
  position: relative;
  width: 100%;
  background-image: url(../assets/imagen-contacto.jpg);
}

.contacto_title {
  position: relative;
  color: white;
  font-size: 35px;
  padding: 20px;
  text-align: center;
}

.contacto_subtitle {
  color: white;
  font-size: 20px;
  display: grid;
  grid-template-columns: 90px 200px;
  margin: auto;
  align-items: center;
}

.logos {
  color: white;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 500px 500px;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  
  .contacto_title {
    position: relative;
    color: white;
    font-size: 30px;
    padding: 20px;
    text-align: center;
  }

  .contacto_subtitle {
    color: white;
    font-size: 20px;
    display: grid;
    grid-template-columns: auto auto;
    margin: 10px;
    align-items: center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
  }
  
}