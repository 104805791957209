.hero {
    position: relative;
    width: 100%;
    height: 85vh;
  }
  
  .hero__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
  
  .hero__title {
    position: absolute;
    bottom: 5%;
    color: white; 
    font-size: 3.5rem;
    padding: 15px;

  }


  